import { boolean, pgEnum, pgTable, text, timestamp, uuid } from 'drizzle-orm/pg-core';
import { Content, creatorContent, User, users } from './schema';
import { is, relations } from 'drizzle-orm';

export const type = pgEnum('notification_type', ['info', 'tip', 'sub', 'colab']);

export const NotificationsTable = pgTable('notifications', {
    id: uuid('id').defaultRandom().primaryKey(),
    title: text('title').notNull(),
    description: text('description').notNull(),
    type: text('type').notNull(),
    isRead: boolean('isRead').default(false),
    imagePath: text('image_path'),
    forUser: text('for_user')
        .notNull()
        .references(() => users.id),
    fromUser: text('from_user')
        .notNull()
        .references(() => users.id),
    createdAt: timestamp('created_at', { mode: 'string' }).defaultNow().notNull(),
    updatedAt: timestamp('updated_at', { mode: 'string' }).defaultNow().notNull()
});

export const notificationRelations = relations(NotificationsTable, ({ one }) => ({
    forUser: one(users, {
        fields: [NotificationsTable.forUser],
        references: [users.id],
        relationName: 'forUser'
    }),
    fromUser: one(users, {
        fields: [NotificationsTable.fromUser],
        references: [users.id],
        relationName: 'fromUser'
    })
}));

export const ColabRequestTable = pgTable('colab_requests', {
    id: uuid('id').defaultRandom().primaryKey(),
    fromUser: text('fromUser')
        .notNull()
        .references(() => users.id),
    toUser: text('toUser')
        .notNull()
        .references(() => users.id),
    isAccepted: boolean('isAccepted').default(false),
    createdAt: timestamp('created_at', { mode: 'string' }).defaultNow().notNull(),
    updatedAt: timestamp('updated_at', { mode: 'string' }).defaultNow().notNull(),
    contentId: uuid('content_id')
        .references(() => creatorContent.id)
        .notNull()
});

export const colabRequestRelations = relations(ColabRequestTable, ({ one }) => ({
    fromUser: one(users, {
        fields: [ColabRequestTable.fromUser],
        references: [users.id],
        relationName: 'fromUser'
    }),
    toUser: one(users, {
        fields: [ColabRequestTable.toUser],
        references: [users.id],
        relationName: 'toUser'
    }),
    content: one(creatorContent, {
        fields: [ColabRequestTable.contentId],
        references: [creatorContent.id]
    })
}));

export type Notification = typeof NotificationsTable.$inferSelect;
export type RichNotification = Notification & {
    fromUser: User;
};

export type ColabRequest = typeof ColabRequestTable.$inferSelect;
export type RichColabRequest = ColabRequest & {
    fromUser: User;
    content: Content;
};
